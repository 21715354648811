import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./src/globalStyles"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
