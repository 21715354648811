import { createGlobalStyle } from "styled-components"
import { darken } from "polished"

const highlightAngles = {
  small: 1,
  medium: 0.5,
  large: 0.25,
}

export const theme = {
  main: {
    primary: "#0E103D",
    secondary: "#69306D",
    active: "#A5668B",
    inactive: "#636165",
    background: "#F7F2F3",
    important: "#DDDDEA",

    mixins: {
      highlighted: ({ theme, size, strokeColor }) => `
        &:before {
          content: " ";
          position: absolute;
          top: -2px;
          right: -6px;
          bottom: 2px;
          left: -6px;
          z-index: -1;
          transform: rotate(-${highlightAngles[size || "small"]}deg);
          background-color: ${strokeColor || theme.main.primary};
        }
      `,
    },
  },
}

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Eczar:400,800|Open+Sans|Raleway:400,800&display=swap');
  
  html, body {
    background: ${({ theme }) => theme.main.background};
    height: 100%;
    > #___gatsby {
      height: 100%;
      > div {
        min-height: 100%;
        position: relative;
      }
    }
  }
  
  * {
    font-family: "Twemoji Country Flags", "Open Sans", sans-serif;
  }
  p {
    font-family: "Twemoji Country Flags", Raleway, sans-serif;
  }
  h1, h2, h3, h4 {
    font-family: "Twemoji Country Flags", Eczar, serif;
    * {
      font-family: "Twemoji Country Flags", Eczar, serif;
    }
  }
  
  a:not(.btn):not([href^="mailto:"]):not([href^="tel:"]) {
    color: ${({ theme }) => theme.main.primary};
    &:hover, &:focus {
      color: ${({ theme }) => darken(0.1, theme.main.active)};    
    }
  }
  
  .btn-primary {
    background-color: ${({ theme }) => theme.main.secondary};
    border-color: ${({ theme }) => darken(0.01, theme.main.secondary)};
    &:hover, &:focus {
      background-color: ${({ theme }) => darken(0.1, theme.main.active)};    
      border-color: ${({ theme }) => darken(0.11, theme.main.active)};    
    }
  }
`
